const SAAS_ENDPOINT_URL = process.env.SAAS_ENDPOINT_URL; // used by older SP versions
const ENDPOINT_URL_DATA_ATTRIBUTE = 'data-endpoint-url'; // used in SPS

export const getEndpointUrl = () => {
  const currentScript = document.querySelector(
    `script[${ENDPOINT_URL_DATA_ATTRIBUTE}]`,
  );
  const endpointUrl =
    SAAS_ENDPOINT_URL ??
    currentScript?.getAttribute(ENDPOINT_URL_DATA_ATTRIBUTE);
  const fullEndpointUrl = endpointUrl?.startsWith('/')
    ? `${window.location.origin}${endpointUrl}`
    : endpointUrl;
  return fullEndpointUrl;
};

export function throttle(func, delay) {
  let timeoutId;
  let lastExecutedTime = 0;

  return function (...args) {
    const currentTime = Date.now();
    const timeSinceLastExecution = currentTime - lastExecutedTime;

    if (timeSinceLastExecution < delay) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        lastExecutedTime = currentTime;
        func(args);
      }, delay);
    } else {
      lastExecutedTime = currentTime;
      func(args);
    }
  };
}
