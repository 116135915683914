import { MessagesToSP } from '@/shared/SalesPilotConstants';
import { getEndpointUrl } from './utils';

const IFRAME_ID = process.env.IFRAME_ID;

function send(actionName, payload) {
  const iframe = document.getElementById(IFRAME_ID);
  const endpointUrl = getEndpointUrl();

  if (iframe) {
    iframe.contentWindow.postMessage(
      { action: actionName, payload },
      endpointUrl,
    );
  }
}

function changeIframePosition(right, bottom) {
  const iframe = document.getElementById(IFRAME_ID);

  if (iframe) {
    iframe.style.right = right;
    iframe.style.bottom = bottom;
  }
}

export const notifyHostWasResized = () => {
  send(MessagesToSP.NOTIFY_HOST_WAS_RESIZED, window.innerWidth);
};

window.salesPilotPostMessages = {
  send,
  notifyHostWasResized,
  notifyUserHasLoggedIn: () => send(MessagesToSP.NOTIFY_USER_HAS_LOGGED_IN),
  notifyUserHasLoggedOut: () => send(MessagesToSP.NOTIFY_USER_HAS_LOGGED_OUT),
  notifyUserHasSignedUp: () => send(MessagesToSP.NOTIFY_USER_HAS_SIGNED_UP),
  notifyPendingUserWasCreated: () =>
    send(MessagesToSP.NOTIFY_PENDING_USER_WAS_CREATED),
  changeIframePosition,
  openChatPage: () => send(MessagesToSP.OPEN_CHAT_PAGE),
  openSearchPage: () => send(MessagesToSP.OPEN_SEARCH_PAGE),
  collapse: () => send(MessagesToSP.COLLAPSE),
  hide: () => send(MessagesToSP.HIDE),
  show: () => send(MessagesToSP.SHOW),
  reload: () => send(MessagesToSP.RELOAD),
  addToCart: (payload) => send(MessagesToSP.ADD_TO_CART, payload),
  buyNow: (payload) => send(MessagesToSP.BUY_NOW, payload),
  createQuote: (payload) => send(MessagesToSP.CREATE_QUOTE, payload),
  requestMultiplePartsPrices: (payload) =>
    send(MessagesToSP.REQUEST_MULTIPLE_PARTS_PRICES, payload),
};
